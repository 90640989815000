import React from 'react';
import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useAppContext, useUpdateAppContext } from '../provider/appProvider/AppProvider';
// import { Box } from "@mui/material";

const Header = () => {
  // const team1ProgressBarStyle = {
  //   width: "0%",
  // };
  // const team2ProgressBarStyle = {
  //   width: "0%",
  // };

  // const linksObjList = [
  //   {
  //     "id": 1,
  //     "link": "https://smartvision-media.s3.eu-central-1.amazonaws.com/mp4-assets/test20s.mp4"
  //   },
  //   {
  //     "id": 2,
  //     "link": "https://smartvision-media.s3.eu-central-1.amazonaws.com/mp4-assets/test-video-2(red-grey).mp4"
  //   },
  //   {
  //     "id": 3,
  //     "link": "https://smartvision-media.s3.eu-central-1.amazonaws.com/mp4-assets/test-video-5(white-grey).mp4"
  //   },
  // ];

  const statsObj = {
    counterLT: '',
    counterRT: '',
    counterLB: '',
    counterRB: '',
    leftBottomPercents: '',
    rightBottomPercents: '',
    leftTopPercents: '',
    rightTopPercents: '',
    halfLeftPercents: '',
    halfRightPercents: '',
    ballPossesPercenta_Team1: '',
    ballPossesPercenta_Team2: '',
    countBallInPenArea_Team1: '',
    countBallInPenArea_Team2: '',
    isImPenArea_Team1: '',
    isImPenArea_Team2: '',
    isTopArea_team1: '',
    isTopArea_team2: '',
    isMiddleArea_team1: '',
    isMiddleArea_team2: '',
    isBottomArea_team1: '',
    isBottomArea_team2: '',
    total_attack_team1: '',
    total_attack_team2: '',
  };

  const { placeholderImage } = useAppContext();
  const { toggleSidebarOpen, changePlaceholderImage } = useUpdateAppContext();
  const [socketURL, setSocketURL] = useState<string>("162.55.189.48:5002");
  const [isSocketConnected, setSocketConnected] = useState<boolean>(false);
  const [isAPIConnected, setAPIConnected] = useState<boolean>(false);

  const [input, setInput] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [indexFrame, setIndexFrame] = useState(0);
  const [stateValue, setStateValue] = useState("None");
  const [statsResult, setStatsResult] = useState(statsObj);
  const [team1BallPossStyle, setTeam1BallPossStyle] = useState("0%");
  const [team2BallPossStyle, setTeam2BallPossStyle] = useState("0%");
  const [team1BallPenaltyStyle, setTeam1BallPenaltyStyle] = useState("0%");
  const [team2BallPenaltyStyle, setTeam2BallPenaltyStyle] = useState("0%");
  const [ballLeftStyle, setBallLeftStyle] = useState("0%");
  const [ballRightStyle, setBallRightStyle] = useState("0%");
  const [team1AttackStyle, setTeam1AttackStyle] = useState("0%");
  const [team2AttackStyle, setTeam2AttackStyle] = useState("0%");

  const socket = useRef<Socket>();
  // console.log("socketURL", socketURL);

  const handleInput = (event: any) => {
    // event.preventDefault();
    setInput(event.target.value);
  };

  const logValue = async () => {
    console.log("input: ", input);
    setDisabledBtn(true);
    pingAPI(input);
    // console.log("disabledBtn: ", disabledBtn);
    await new Promise<void>((resolve) =>
      setTimeout(() => {
        resolve();
      }, 10000)
    );
    setDisabledBtn(false);
    // console.log("disabledBtn: ", disabledBtn);
  };

  const disconnectSocket = () => {
    console.log("Disconnecting...", isSocketConnected, socket.current);
    socket?.current?.disconnect();
  };

  const pingAPI = async (url: string) => {
    // const pingResult = await fetch("http://localhost:8080/ping", {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   // mode: "no-cors",
    // });
    // console.log(pingResult);

    // const data = await pingResult.text();
    // console.log("PingResult", JSON.parse(data));
    // if (pingResult.ok) {
    //   setAPIConnected(true);
    //   const invoke = await fetch(
    //     "http://localhost:8080/invoke?scenarioId=358643e6-bce3-47a5-bfb6-4ab35e88222a&sourceUrl=https://smartvision-media.s3.eu-central-1.amazonaws.com/mp4-assets/test20s.mp4&mode=local",
    //     {
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   const data = await invoke.json();
    //   console.log("invoke", data);
    // }

    // const urlVideo = `http://localhost:8080/invoke?scenarioId=358643e6-bce3-47a5-bfb6-4ab35e88222a&sourceUrl=${url}&mode=local`;
    const urlVideo = `http://www.budgetplan.eu/invoke?scenarioId=358643e6-bce3-47a5-bfb6-4ab35e88222a&sourceUrl=${url}&mode=local`;
    const invoke = await fetch(
      urlVideo,
      {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const data = await invoke.json();
    console.log("invoke", data);
  };

  useEffect(() => {
    console.log("Socket connected? ", isSocketConnected);
  }, [isSocketConnected]);

  //SocketIO
  useEffect(() => {
    if (!socketURL) return;
    socket.current = io(socketURL);

    // Connect
    socket?.current?.on("connect", () => {
      setSocketConnected(true);
      console.log("[ScenarioForm] socketIO connect:", socket?.current?.id);
    });

    // Invoke
    socket?.current?.on("invoke", (message: any) => {
      console.log(
        "[ScenarioForm] socketIO invoke (echo aus system) event:",
        message
      );
    });

    // Images
    socket?.current?.on("imageEcho", (data: string) => {
      // console.log("data", data);
      changePlaceholderImage(data);
    });

    // tracking
    socket?.current?.on("tracking", (data: any) => {
      // console.log("data", data);
      // console.log("index", data.index);
      // console.log("stateValue", data.payload.stateValue);
      // console.log("statsResult", data.payload.context);

      setIndexFrame(data.index);
      setStateValue(data.payload.stateValue);

      // to avoid displaying "NaN"
      if (data.payload.context.ballPossesPercenta_Team1 || data.payload.context.ballPossesPercenta_Team2 || data.payload.context.ballPossesPercenta_Team2 || data.payload.context.counterLT || data.payload.context.counterLB || data.payload.context.counterRT || data.payload.context.counterRB) {
        setStatsResult(data.payload.context);

        // ballpossession
        setTeam1BallPossStyle(data.payload.context.ballPossesPercenta_Team1 + '%');
        setTeam2BallPossStyle(data.payload.context.ballPossesPercenta_Team2 + '%');

        // ball in penalty areas
        var team1Pen = data.payload.context.countBallInPenArea_Team1 * 100 / (data.payload.context.countBallInPenArea_Team1 + data.payload.context.countBallInPenArea_Team2);
        var team2Pen = data.payload.context.countBallInPenArea_Team2 * 100 / (data.payload.context.countBallInPenArea_Team1 + data.payload.context.countBallInPenArea_Team2);
        setTeam1BallPenaltyStyle(team1Pen + '%');
        setTeam2BallPenaltyStyle(team2Pen + '%');

        // ball in half field
        setBallLeftStyle(data.payload.context.halfLeftPercents + '%');
        setBallRightStyle(data.payload.context.halfReftPercents + '%');

        // total attacks
        var team1Attacks = data.payload.context.total_attack_team1 * 100 / (data.payload.context.total_attack_team1 + data.payload.context.total_attack_team2);
        var team2Attacks = data.payload.context.total_attack_team2 * 100 / (data.payload.context.total_attack_team1 + data.payload.context.total_attack_team2);
        setTeam1AttackStyle(team1Attacks + '%');
        setTeam2AttackStyle(team2Attacks + '%');
      }
      // changePlaceholderImage(data);
    });

    socket?.current?.on("disconnect", () => {
      setSocketConnected(false);
      console.log("[ScenarioForm] socketIO disconnect:", socket.current?.id);
    });
  }, [socketURL]);

  return (
    <div>
      <div className="header">
        {/* <h1 className="text-danger">GRID x RAWSKILL</h1> */}
        <img className="logo" src="./images/Group356-3.png" alt="GRIDxRAWSKILL" />
      </div>

      <div className="visualizer">
        <div className="row">
          <div className="col-7">
            <div>
              <form>
                <div className="">Stream Source:</div>
                <div className="row videoLink-form">
                  <div className="col-9">
                    {/* <input className="input-videolink" onChange={handleInput} placeholder="https://smartvision-media.s3.eu-central-1.amazonaws.com/mp4-assets/test20s.mp4" /> */}
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-default">URL</span>
                      </div>
                      <input type="text" className="form-control input-videolink" onChange={handleInput} aria-label="input-videolink" aria-describedby="inputGroup-sizing-default" placeholder="https://smartvision-media.s3.eu-central-1.amazonaws.com/mp4-assets/test20s.mp4" />
                    </div>
                  </div>

                  <div className="col-3">
                    <button className="btn btn-primary start-btn" onClick={logValue} disabled={disabledBtn}>START</button>
                    {/* <button className="btn btn-primary m-1" onClick={disconnectSocket}>DISCONNECT</button> */}
                  </div>
                </div>
              </form>
            </div>

            <div className="video">
              <img className="input-video" src={placeholderImage} alt="input-video" />
            </div>

            <div className="stats card-item">
              <h4>TECHNICAL</h4>
              <hr></hr>
              <div className="row technical">
                <div className="col-6">Index</div>
                <div className="col-6">{indexFrame}</div>
              </div>
              <div className="row technical">
                <div className="col-6">Status</div>
                <div className="col-6">{stateValue}</div>
              </div>
            </div>

            <div className="stats card-item">
              <h4>POSITIONING OF THE BALL</h4>
              <hr></hr>
              <div className="row positioning">
                <div className="col-3">
                  <p>{Number(statsResult.leftTopPercents).toFixed(2)}%</p>
                  <p>Left-Top</p>
                </div>
                <div className="col-3">
                  <p>{Number(statsResult.rightTopPercents).toFixed(2)}%</p>
                  <p>Right-Top</p>
                </div>
                <div className="col-3">
                  <p>{Number(statsResult.leftBottomPercents).toFixed(2)}%</p>
                  <p>Left-Bottom</p>
                </div>
                <div className="col-3">
                  <p>{Number(statsResult.rightBottomPercents).toFixed(2)}%</p>
                  <p>Right-Bottom</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-5">
            <div className="comparision card-item">
              <h4>STATISTICS</h4>
              <hr></hr>

              <div className="two-teams progressbar">
                <div className="row">
                  <div>TEAM 1</div>
                  <div>TEAM 2</div>
                </div>
              </div>

              {/* BALLPOSSESSION */}
              <div className="ball-possession progressbar">
                <div className="row">
                  <div>{Number(statsResult.ballPossesPercenta_Team1).toFixed(2)}%</div>
                  <div>{Number(statsResult.ballPossesPercenta_Team2).toFixed(2)}%</div>
                </div>

                <div className="progress">
                  <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: team1BallPossStyle }}></div>
                  <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{ width: team2BallPossStyle }}></div>
                </div>

                <p className='progressbar-title'>BALLPOSSESSION</p>
              </div>

              {/* BALL IN HALF FIELD */}
              <div className="ball-half progressbar">
                <div className="row">
                  <div>{Number(statsResult.halfLeftPercents).toFixed(2)}%</div>
                  <div>{Number(statsResult.halfRightPercents).toFixed(2)}%</div>
                </div>

                <div className="progress">
                  <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: ballLeftStyle }}></div>
                  <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{ width: ballRightStyle }}></div>
                </div>

                <p className='progressbar-title'>BALL-IN-HALF-FIELD</p>
              </div>

              {/* BALL IN PENALTY AREA */}
              <div className="ball-penalty progressbar">
                <div className="row">
                  <div>{(Number(statsResult.countBallInPenArea_Team1) * 100 / (Number(statsResult.countBallInPenArea_Team1) + Number(statsResult.countBallInPenArea_Team2))).toFixed(2)}%</div>
                  <div>{(Number(statsResult.countBallInPenArea_Team2) * 100 / (Number(statsResult.countBallInPenArea_Team1) + Number(statsResult.countBallInPenArea_Team2))).toFixed(2)}%</div>
                </div>

                <div className="progress">
                  <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: team1BallPenaltyStyle }}></div>
                  <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{ width: team2BallPenaltyStyle }}></div>
                </div>

                <p className='progressbar-title'>BALL-IN-PENALTY-AREA</p>
              </div>

              

              {/* TOTAL ATTACKS */}
              <div className="total-attacks progressbar">
                <div className="row">
                  <div>{(Number(statsResult.total_attack_team1) * 100 / (Number(statsResult.total_attack_team1) + Number(statsResult.total_attack_team2))).toFixed(2)}%</div>
                  <div>{(Number(statsResult.total_attack_team2) * 100 / (Number(statsResult.total_attack_team1) + Number(statsResult.total_attack_team2))).toFixed(2)}%</div>
                </div>

                <div className="progress">
                  <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: team1AttackStyle }}></div>
                  <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{ width: team2AttackStyle }}></div>
                </div>

                <p className='progressbar-title'>TOTAL-ATTACKS</p>
              </div>

              <div className="team-1">
                <h5>Team 1:</h5>
                {/* <div className="row">
                  <div className="col-6">Ball Possession:</div>
                  <div className="col-6">{Number(statsResult.ballPossesPercenta_Team1).toFixed(2)}%</div>
                </div>
                <div className="row">
                  <div className="col-6">Ball In Penalty Area:</div>
                  <div className="col-6">{statsResult.countBallInPenArea_Team1} ({(Number(statsResult.countBallInPenArea_Team1) * 100 / (Number(statsResult.countBallInPenArea_Team1) + Number(statsResult.countBallInPenArea_Team2))).toFixed(2)}%)</div>
                </div> */}
              </div>


              <div className='attack-zone'>
                <p className="attack-zone-titel">ATTACK ZONE</p>
                <div className="row">
                  <div className="col-6">Top Area:</div>
                  <div className="col-6">{(Number(statsResult.isTopArea_team1) / Number(statsResult.total_attack_team1) * 100).toFixed(2)}%</div>
                </div>
                <div className="row">
                  <div className="col-6">Middle Area:</div>
                  <div className="col-6">{(Number(statsResult.isMiddleArea_team1) / Number(statsResult.total_attack_team1) * 100).toFixed(2)}%</div>
                </div>
                <div className="row">
                  <div className="col-6">Bottom Area:</div>
                  <div className="col-6">{(Number(statsResult.isBottomArea_team1) / Number(statsResult.total_attack_team1) * 100).toFixed(2)}%</div>
                </div>
              </div>

              <hr></hr>

              <div className="team-2">
                <h5>Team 2:</h5>
                {/* <div className="row">
                  <div className="col-6">Ball Possession:</div>
                  <div className="col-6">{Number(statsResult.ballPossesPercenta_Team2).toFixed(2)}%</div>
                </div>
                <div className="row">
                  <div className="col-6">Ball In Penalty Area:</div>
                  <div className="col-6">{statsResult.countBallInPenArea_Team2} ({(Number(statsResult.countBallInPenArea_Team2) * 100 / (Number(statsResult.countBallInPenArea_Team1) + Number(statsResult.countBallInPenArea_Team2))).toFixed(2)}%)</div>
                </div> */}
              </div>

              <div className='attack-zone'>
                <p className="attack-zone-titel">ATTACK ZONE</p>
                <div className="row">
                  <div className="col-6">Top Area:</div>
                  <div className="col-6">{(Number(statsResult.isTopArea_team2) / Number(statsResult.total_attack_team2) * 100).toFixed(2)}%</div>
                </div>
                <div className="row">
                  <div className="col-6">Middle Area:</div>
                  <div className="col-6">{(Number(statsResult.isMiddleArea_team2) / Number(statsResult.total_attack_team2) * 100).toFixed(2)}%</div>
                </div>
                <div className="row">
                  <div className="col-6">Bottom Area:</div>
                  <div className="col-6">{(Number(statsResult.isBottomArea_team2) / Number(statsResult.total_attack_team2) * 100).toFixed(2)}%</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Header
