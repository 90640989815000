import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Body from '../components/Body';

const Visualizer = () => {
  return (
    <div>
      <Header/>
      {/* <Body/> */}
      <Footer/>
    </div>
  )
}

export default Visualizer

