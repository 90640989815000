import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="row">
                <div className="col-4">
                    <span>Copyright &copy; 2023 RawSkill, Inc.</span>
                </div>
                <div className="col-8">
                    <ul className="nav justify-content-end">
                        <li className="nav-item">Terms</li>
                        <li className="nav-item">Privacy Policy and Cookie Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer
