import React from 'react';
import './App.css';
import Visualizer from './pages/Visualizer';
import io from "socket.io-client";

function App() {
  return (
    <div className="App">
      <Visualizer/>
    </div>
  );
}

export default App;
